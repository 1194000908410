import request from "@/utils/request";

/**
 * @return Promise
 */
export function getAgencyCode(agncyKind, isinit, useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/info",
    method: "get",
    params: {
      agncyKind,
      isinit,
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getAgencyResveTime(agncyKind) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/resve-time",
    method: "get",
    params: {
      agncyKind,
    },
  });
}

/**
 * @return Promise
 */
export function getAgencyIp(agncyKind) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/ip",
    method: "get",
    params: {
      agncyKind,
    },
  });
}

/**
 * @return Promise
 */
export function putAgencyCode(addedRecords, changedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/info",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function putAgencyResveTime(addedRecords, changedRecords, deletedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/resve-time",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function putAgencyIp(addedRecords, changedRecords, deletedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/ip",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function getAgency() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/agency-code/agncy",
    method: "get",

  });
}
