<template>
  <div>
    <ejs-dialog
      ref="agencyCodeDetailPopup"
      header="대행사 등록 상세"
      :animationSettings="animationSettings"
      showCloseIcon="true"
      width="1180"
      height="800"
      :position="popupPosition"
      enableResize="true"
      :isModal="true"
    >
      <div class="window agencyCodeDetails">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        대행사 등록 상세  [{{this.agencyCode.agncyCode}}]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
<!--                        <li class="delete">-->
<!--                          <delete-shortcut-button-->
<!--                            :disabled="-->
<!--                              commonMixinIsButtonDisableByAuth(-->
<!--                                'codeMngProductCodeDel'-->
<!--                              )-->
<!--                            "-->
<!--                            v-if="agencyCode.agncyCode"-->
<!--                            v-on:click.native="onDeleteClicked"-->
<!--                          >-->
<!--                            삭제-->
<!--                          </delete-shortcut-button>-->
<!--                        </li>-->
<!--                        <li class="reset">-->
<!--                          <ejs-button-->
<!--                            :disabled="-->
<!--                              commonMixinIsButtonDisableByAuth(-->
<!--                                'codeMngProductCodeReg'-->
<!--                              )-->
<!--                            "-->
<!--                            v-on:click.native="onInitClicked"-->
<!--                          >-->
<!--                            초기화-->
<!--                          </ejs-button>-->
<!--                        </li>-->
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
<!--                      <section class="article-section section-0101">-->
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">
                              대행사 예약 타임 오픈 설정
                            </div>
                          </div>
                        </div>
                        <div class="section-body">
                          <article class="body-article">
                            <section class="article-section section-010101">
                              <div class="section-header">
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li class="add">
                                      <ejs-button
                                          :disabled="
                                            commonMixinIsButtonDisableByAuth(
                                              'agencyRegistrationReg')
                                          "
                                          @click.native="onAddAgncyResveTime"
                                      >
                                        추가
                                      </ejs-button>
                                    </li>
                                    <li class="delete">
                                      <ejs-button
                                          :disabled="
                                            commonMixinIsButtonDisableByAuth(
                                              'agencyRegistrationReg'
                                            )
                                          "
                                          @click.native="onDeleteAgncyResveTime"
                                      >
                                        삭제
                                      </ejs-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <ejs-grid-wrapper
                                  ref="agncyResveTimeGrid"
                                  id="agncyResveTimeGrid"
                                  v-bind="agncyResveTimegridOption"
                                  :allowResizing="true"
                                  :allowSelection="true"
                                  :allowPaging="true"
                                  :position="popupPosition"
                                  @headerCellInfo="resveTimeHeaderCellInfo"
                                  @queryCellInfo="resveTimeQueryCellInfo"
                                  @cellEdit="onGridCellEdit"
                                />
                              </div>
                            </section>
                          </article>
                        </div>
<!--                      </section>-->
                      <!-- 아코디언 : accordion / 닫힘 : close -->
<!--                      <section class="article-section section-0102">-->
                      <p></p>
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">
                              대행사 IP 정보
                            </div>
                          </div>
                        </div>
                        <div class="section-body">
                          <article class="body-article">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section class="article-section section-010201">
                              <div class="section-header">
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li class="add">
                                      <ejs-button
                                        :disabled="
                                          commonMixinIsButtonDisableByAuth(
                                            'agencyRegistrationReg')
                                        "
                                        @click.native="onAddAgncyIp"
                                      >
                                        추가
                                      </ejs-button>
                                    </li>
                                    <li class="delete">
                                      <ejs-button
                                        :disabled="
                                          commonMixinIsButtonDisableByAuth(
                                            'agencyRegistrationReg'
                                          )
                                        "
                                        @click.native="onDeleteAgncyIp"
                                      >
                                        삭제
                                      </ejs-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <ejs-grid-wrapper
                                  ref="agncyIpGrid"
                                  v-bind="agncyIpgridOption"
                                  :provides="agncyIpgridOption.provides"
                                  :columns="agncyIpgridOption.columns"
                                  :position="popupPosition"
                                  :allowResizing="true"
                                  :allowSelection="true"
                                  :allowPaging="true"
                                  :pageSettings="agncyIpgridOption.pageSettings"
                                  :editSettings="agncyIpgridOption.editSettings"
                                  @headerCellInfo="ipHeaderCellInfo"
                                  @queryCellInfo="ipQueryCellInfo"
                                  @cellEdit="onGridCellEdit"
                                />
                                </div>
                              </section>
                          </article>
                        </div>
<!--                      </section>-->
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="reset">
              <ejs-button
                  :disabled="
                              commonMixinIsButtonDisableByAuth(
                                'agencyRegistrationReg'
                              )
                            "
                  v-on:click.native="onInitClicked"
              >
                새로고침
              </ejs-button>
            </li>
            <li class="save keyColor">
              <erp-button
                :disabled="
                  commonMixinIsButtonDisableByAuth('agencyRegistrationReg')
                "
                @click.native="onSaveAgencyDetail"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <ejs-button
                  @click.native="onCloseClicked"
              >
                닫기
              </ejs-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .body-list .list-outer {height: 87px !important}
</style>

<script>
import {maxBy as _maxBy} from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {validateFormRefs} from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ForeignKey, Page, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {getAgencyResveTime, getAgencyIp, putAgencyResveTime, putAgencyIp} from "@/api/agencyCodeRegistration";
import {getFormattedDate} from "@/utils/date";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment/moment";

export default {
  name: "AgencyCodeDetailPopup",
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      agncyResveTimeInfo: [],
      agncyCodeDtlInfo: [],
      agncyIpInfo: [],
      agncyResveTimeInfoLength: 0,
      agncyIpInfoLength: 0,
      commonCodeFields: { text: "comName", value: "comCode" },
      dataSource: [],
      agencyCode: {
        // API 로부터 받을 response property와 동일하게 설정하여야 2-way binding이 정상 작동함
        agncyCode: null,
      },
    };
  },
  computed: {
    agncyResveTimegridOption() {
      return {
        provides: [Edit, Selection, Resize, Page, ForeignKey],
        dataSource: this.agncyResveTimeInfo,
        rowHeight: 24,
        allowResizing: true,
        //isNOColumnDisplay: false,
        pageSettings: {pageSize: 15},
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "_rid",
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            headerText: "NO",
            width: "40",
            textAlign: "Center",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "agncyCode",
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            headerText: "대행사코드",
            width: "40",
            textAlign: "Center",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "applyDate",
            headerText: "적용일자",
            type: "string",
            width: "100",
            textAlign: "Center",
            isDateType : true,
            // format: "##",
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            width: "100",
            textAlign: "Center",
            isCommonCodeField: true,
            editType: "dropdownedit",
            dataSource: commonCodesGetCommonCode("MEMBER_GRADE", true),
          },
          {
            field: "agncyOpenMethod",
            headerText: "대행사오픈방법",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "AGNCY_OPEN_METHOD",
            foreignKeyValue: "comCode",
          },
          {
            field: "webOpenTime",
            headerText: "웹오픈시간",
            type: "integer",
            width: "75",
            textAlign: "center",
            isNumericType: true,
            inputNumberProperty: { maxLength: 10 },
          },
          {
            field: "insertId",
            headerText: "등록자",
            allowFiltering: false,
            type: "string",
            width: "70",
            textAlign: "Center",
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
          {
            field: "updateId",
            headerText: "수정자",
            allowEditing: false,
            type: "integer",
            width: "70",
            minWidth: 16,
            textAlign: "center",
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
        ],
      };
    },
    agncyIpgridOption() {
      return {
        provides: [Edit, Selection, Resize, Page, ForeignKey],
        dataSource: this.agncyIpInfo,
        rowHeight: 24,
        allowResizing: true,
        isNOColumnDisplay: false,
        pageSettings: {pageSize: 15},
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "_rid",
            allowEditing: false,
            allowFiltering: false,
            allowSorting: false,
            headerText: "NO",
            width: "40",
            textAlign: "Center",
            isPrimaryKey: true,
          },
          {
            field: "agncyIp",
            headerText: "대행사 IP",
            type: "string",
            width: "100",
            textAlign: "Center",
          },
          {
            field: "useFlag",
            headerText: "사용여부",
            type: "boolean",
            editType: "booleanedit",
            minWidth: 10,
            width: 50,
            displayAsCheckBox: true,
            textAlign: "center",
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "left",
            allowEditing: true,
          },
          {
            field: "insertId",
            headerText: "등록자",
            type: "string",
            width: "80",
            textAlign: "Center",
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
        ],
      };
    },
  },
  methods: {
    resveTimeHeaderCellInfo(args) {
      if (
          args.cell.column.field === "applyDate"       ||
          args.cell.column.field === "memberGrade"     ||
          args.cell.column.field === "agncyOpenMethod" ||
          args.cell.column.field === "webOpenTime"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    // resveTimeQueryCellInfo(args) {
    //   // const {
    //   //   cell,
    //   //   column: {field},
    //   // } = args;
    //   //
    //   // if (field === "agncyCode" || field === "memberGrade") {
    //   //   cell.classList.add(this.$t("className.grid.clickArea"));
    //   // }
    //   // if (
    //   //     field === "agncyCode"       ||
    //   //     field === "applyDate"       ||
    //   //     field === "memberGrade"     ||
    //   //     field === "agncyOpenMethod" ||
    //   //     field === "webOpenTime"     ||
    //   //     field === "insertId"        ||
    //   //     field === "insertDt"        ||
    //   //     field === "updateId"        ||
    //   //     field === "updateDt"
    //   // ) {
    //   //   cell.classList.add(this.$t("className.grid.modifyArea"));
    //   // }
    // },
    ipHeaderCellInfo(args) {
      if (args.cell.column.field === "agncyIp" ||
          args.cell.column.field === "useFlag"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    ipQueryCellInfo(args) {
      const {
        cell,
        column: {field},
      } = args;

      if (field === "useFlag") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    validateFormRefs,
    async getAgencyResveTime() {
      this.isResveTimeAddMode = false;

      const agncyKind = this.agencyCode.agncyCode;
      const response = await getAgencyResveTime(agncyKind);
      const agncyResveTimeInfoList = response.value.agencyResveTimeInfoList;

      this.agncyResveTimeInfo = agncyResveTimeInfoList.map((item, index) => {
        item["agncyCode"      ] || (item["agncyCode"      ] = null);
        item["applyDate"      ] || (item["applyDate"      ] = null);
        item["memberGrade"    ] || (item["memberGrade"    ] = null);
        item["agncyOpenMethod"] || (item["agncyOpenMethod"] = null);
        item["webOpenTime"    ] || (item["webOpenTime"    ] = null);
        item["insertId"       ] || (item["insertId"       ] = null);
        item["insertDt"       ] || (item["insertDt"       ] = null);
        item["updateId"       ] || (item["updateId"       ] = null);
        item["updateDt"       ] || (item["updateDt"       ] = null);

        return {
          ...item,
          applyDate: getFormattedDate(item.applyDate, "YYYY-MM-DD"),
          _rid: index + 1,
        };
      });

      this.agncyResveTimeInfoLength = this.agncyResveTimeInfo.length;
    },

    async getAgencyIp() {
      const agncyKind = this.agencyCode.agncyCode;
      const response = await getAgencyIp(agncyKind);
      const agncyIpInfoList = response.value.agencyIpInfoList;

      this.agncyIpInfo = agncyIpInfoList.map((item, index) => {
        item["agncyIp" ] || (item["agncyIp" ] = null);
        item["useFlag" ] || (item["useFlag" ] = null);
        item["remarks" ] || (item["remarks" ] = null);
        item["insertId"] || (item["insertId"] = null);
        item["insertDt"] || (item["insertDt"] = null);

        return {
          ...item,
          _rid: index + 1,
        };
      });

      this.agncyIpInfoLength = this.agncyIpInfo.length;
    },
    onAddAgncyResveTime() { //예약타임오픈설정 추가 버튼
      let _rid;
      if (this.agncyResveTimeInfo.length > 0) {
        const currentViewRecords = this.$refs.agncyResveTimeGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const {addedRecords} = this.$refs.agncyResveTimeGrid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addResveTimeRecordObject = {
        _rid       : _rid + 1,
        agncyCode  : null,
        applyDate  : moment().format("YYYY-MM-DD"),
        memberGrade: null,
        agncyOpenMethod: null,
        webOpenTime: null,
        insertId   : null,
        insertDt   : null,
        updateId   : null,
        updateDt   : null
      };
      this.$refs.agncyResveTimeGrid.addRecord(addResveTimeRecordObject);

      const currentViewRecords = this.$refs.agncyResveTimeGrid.getBatchCurrentViewRecords();
      const idxToSelect = currentViewRecords.findIndex(
          (record) => record._rid === addResveTimeRecordObject._rid
      );

     this.$refs.agncyResveTimeGrid.editCell(idxToSelect, "applyDate");
    },
    onDeleteAgncyResveTime() { // 예약타임 오픈 삭제 버튼
      const currentViewRecords = this.$refs.agncyResveTimeGrid.getBatchCurrentViewRecords();
      if (currentViewRecords.length > 0) {
        const getFirstFieldName = this.$refs.agncyResveTimeGrid.getColumnFieldNames();
        let selectedRowIndexes = this.$refs.agncyResveTimeGrid.getSelectedRowIndexes();
        if (selectedRowIndexes < 0 ) {
          selectedRowIndexes = 0;
          this.$refs.agncyResveTimeGrid.editCell(0, getFirstFieldName);
        }
        this.$refs.agncyResveTimeGrid.deleteRecord();
        if (selectedRowIndexes > 0) {
          this.$refs.agncyResveTimeGrid.editCell(selectedRowIndexes, getFirstFieldName);
        }
        this.infoToast(this.$t("main.popupMessage.deletedSave"));
      } else {
        this.errorToast(this.$t("main.popupMessage.noData"));
      }
    },
    onAddAgncyIp() {  //IP 정보 추가 버튼
      let _rid;
      if (this.agncyIpInfo.length > 0) {
        const currentViewRecords = this.$refs.agncyIpGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const {addedRecords} = this.$refs.agncyIpGrid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addIpRecordObject = {
        _rid     : _rid + 1,
        agncyCode: null,
        agncyIp  : null,
        useFlag  : true,
        remarks  : null,
        insertId : null,
        insertDt : null
      };
      this.$refs.agncyIpGrid.addRecord(addIpRecordObject);


      const currentViewRecords = this.$refs.agncyIpGrid.getBatchCurrentViewRecords();
      const idxToSelect = currentViewRecords.findIndex(
          (record) => record._rid === addIpRecordObject._rid
      );

      this.$refs.agncyIpGrid.editCell(idxToSelect, "agncyIp");
    },
    onDeleteAgncyIp() {  // IP 정보 삭제 버튼
      const currentViewRecords = this.$refs.agncyIpGrid.getBatchCurrentViewRecords();
      if (currentViewRecords.length > 0) {
        const getFirstFieldName = this.$refs.agncyIpGrid.getColumnFieldNames();
        let selectedRowIndexes = this.$refs.agncyIpGrid.getSelectedRowIndexes();
        if (selectedRowIndexes < 0 ) {
          selectedRowIndexes = 0;
          this.$refs.agncyIpGrid.editCell(0, getFirstFieldName);
        }
        this.$refs.agncyIpGrid.deleteRecord();
        if (selectedRowIndexes > 0) {
          this.$refs.agncyIpGrid.editCell(selectedRowIndexes, getFirstFieldName);
        }
        this.infoToast(this.$t("main.popupMessage.deletedSave"));
      } else {
        this.errorToast(this.$t("main.popupMessage.noData"));
      }
    },
    async onSaveAgencyResveTime() {
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.agncyResveTimeGrid.getBatchChanges();

      // if (!this.$refs["agncyResveTimeGrid"].validate()) {
      //   return;
      // }

      await  putAgencyResveTime(
        addedRecords.map((r) => ({
          ...r,
          agncyCode  : this.agencyCode.agncyCode,
          applyDate  : String(r.applyDate ? r.applyDate.toString().replaceAll("-","") : ""),
          webOpenTime: Number(r.webOpenTime ? r.webOpenTime.toString().replaceAll(",", "") : ""),
        })),
        changedRecords.map((r) => ({
          ...r,
          agncyCode: this.agencyCode.agncyCode,
          applyDate: String(r.applyDate ? r.applyDate.toString().replaceAll("-","") : ""),
          webOpenTime: Number(r.webOpenTime ? r.webOpenTime.toNumber().replaceAll(",", "") : ""),
        })),
        deletedRecords.map((r) => ({
          ...r,
          agncyCode: this.agencyCode.agncyCode,
          applyDate: String(r.applyDate ? r.applyDate.toString().replaceAll("-","") : ""),
          webOpenTime: Number(r.webOpenTime ? r.webOpenTime.toString().replaceAll(",", "") : ""),
        })),
      );
    },
    async onSaveAgencyIp() {
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.agncyIpGrid.getBatchChanges();
      // if (!this.$refs["agncyIpGrid"].validate()) {
      //   return;
      // }

      await  putAgencyIp(
          addedRecords.map((r) => ({
            ...r,
            agncyCode: this.agencyCode.agncyCode,
          })),
          changedRecords.map((r) => ({
            ...r,
            agncyCode: this.agencyCode.agncyCode,
          })),
          deletedRecords.map((r) => ({
            ...r,
            agncyCode: this.agencyCode.agncyCode,
          })),
      );

      //this.infoToast(this.$t("main.popupMessage.saved"));
    },
    async onSaveAgencyDetail() {
      //적용일자 중복 체크
      const memberGradeValidationResult = this.agncyResveTimeInfo.every(
        (item) => {
          const currentViewResveTimeInfo = this.$refs.agncyResveTimeGrid.getBatchCurrentViewRecords();

          let duplicateMemberGrade = false;
          currentViewResveTimeInfo.forEach((data, idx) => {
            if (currentViewResveTimeInfo.filter((info, infoIdx) => idx !== infoIdx && info.applyDate === data.applyDate).length > 0) {
              duplicateMemberGrade = true;
            }
          });

          if (duplicateMemberGrade) {
            this.errorToast("대행사 예약 타임 오픈 설정의 적용일자가 중복됩니다");
            return false;
          }

          const value = item.applyDate;
          if (value === null || value === undefined || value === "") {
            // null, undefined, empty string은 regexp 에서는 체크하지 않는다
            return true;
          }

          return true;
        }
      );

      if (!memberGradeValidationResult) {
        return;
      }

      //IP 중복 체크
      console.log("count: ", this.agncyIpInfo.length);
      if (this.agncyIpInfo.length > 0) {
        const ipValidationResult = this.agncyIpInfo.every(
            (item) => {
              //const currentViewIpInfo = this.$refs.agncyIpGrid.getBatchCurrentViewRecords();
              const currentViewIpInfo = this.$refs.agncyIpGrid.getBatchCurrentViewRecords().map(data => ({
                ...data,
                agncyIp: String(data.agncyIp ? data.agncyIp.toString().replaceAll(" ", "") : ""),
              }));

              let duplicateIp = false;
              currentViewIpInfo.forEach((data, idx) => {
                if (currentViewIpInfo.filter((info, infoIdx) => idx !== infoIdx && info.agncyIp === data.agncyIp).length > 0) {
                  duplicateIp = true;
                }
              });

              if (duplicateIp) {
                this.errorToast("대행사 IP 정보의 대행사 IP가 중복됩니다");
                return false;
              }

              const value = item.agncyIp;
              if (value === null || value === undefined || value === "") {
                // null, undefined, empty string은 regexp 에서는 체크하지 않는다
                return true;
              }

              return true;
            }
        );


        if (!ipValidationResult) {
          return;
        }
      } else {
        const ipValidationResult = this.$refs.agncyIpGrid.getBatchCurrentViewRecords().every(
            (item) => {
              //const currentViewIpInfo = this.$refs.agncyIpGrid.getBatchCurrentViewRecords();
              const currentViewIpInfo = this.$refs.agncyIpGrid.getBatchCurrentViewRecords().map(data => ({
                ...data,
                agncyIp: String(data.agncyIp ? data.agncyIp.toString().replaceAll(" ", "") : ""),
              }));

              let duplicateIp = false;
              currentViewIpInfo.forEach((data, idx) => {
                if (currentViewIpInfo.filter((info, infoIdx) => idx !== infoIdx && info.agncyIp === data.agncyIp).length > 0) {
                  duplicateIp = true;
                }
              });

              if (duplicateIp) {
                this.errorToast("대행사 IP 정보의 대행사 IP가 중복됩니다");
                return false;
              }

              const value = item.agncyIp;
              if (value === null || value === undefined || value === "") {
                // null, undefined, empty string은 regexp 에서는 체크하지 않는다
                return true;
              }

              return true;
            }
        );


        if (!ipValidationResult) {
          return;
        }

      }


      try {
        await this.onSaveAgencyResveTime();
        await this.onSaveAgencyIp();

        this.infoToast(this.$t("main.popupMessage.saved"));

        await this.getAgencyResveTime();
        await this.getAgencyIp();
      }
      catch(e) {
        await this.getAgencyResveTime();
        await this.getAgencyIp();
      }
    },
    async showAgencyCodeDetailPopup(popupData) {
      this.agencyCode.agncyCode = popupData.agncyCode;
      this.$refs.agencyCodeDetailPopup.show();

      await  this.getAgencyResveTime();
      this.count = this.agncyResveTimeInfo.length>0?this.agncyResveTimeInfo.length:0;
      await  this.getAgencyIp();
      this.count = this.agncyIpInfo.length>0?this.agncyIpInfo.length:0;

    },
    async onInitClicked() {
      await this.getAgencyResveTime();
      await this.getAgencyIp();
    },
    onCloseClicked() {
      this.$emit("popupEvent","popupReload");
      this.$refs.agencyCodeDetailPopup.hide();
    },
    onGridCellEdit(args) {
      if (args.rowData.agncyCode) {
        args.cancel = true;
      }
      if(args.columnName === "useFlag") {
        args.cancel = false;
      }
    },
  },
};
</script>
